import { Component, HostBinding } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { StringSignal } from '@studiohyperdrive/ngx-utils';
import { map } from 'rxjs';

import { BrowserService } from '@cjm/shared/core';
import { StatusService } from '@cjm/shared/status';
import { ButtonClasses, ButtonComponent, ImageComponent, LayoutContainerComponent } from '@cjm/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	selector: 'cjm-maintenance-page',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
	imports: [LayoutContainerComponent, ButtonComponent, ImageComponent, TranslateModule]
})
export class MaintenancePageComponent {
	@HostBinding('class.p-maintenance') private readonly hasRootClass: boolean = true;

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly redirectI18nKeys: typeof RedirectI18nKeys = RedirectI18nKeys;
	public readonly statusMessage: StringSignal = toSignal(
		this.statusService.status$.pipe(map((status) => status.message))
	);

	constructor(
		private readonly browserService: BrowserService,
		private readonly statusService: StatusService
	) {}

	/**
	 * Reload the document
	 */
	public retryStatus(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.location.reload();
		});
	}
}
